import React, {useRef, useState, useEffect} from 'react'
import './HowItWorks.css'
import HowItWorksSlide from './HowItWorksSlide/HowItWorksSlide'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import SwitchButton from '../SwitchButton/SwitchButton';

import slide11 from '../../Media/Slide11.png'
import slide12 from '../../Media/Slide12.png'
import slide13 from '../../Media/Slide13.png'
import slide14 from '../../Media/Slide14.png'

import slide21 from '../../Media/Slide21.png'
import slide22 from '../../Media/Slide22.png'
import slide23 from '../../Media/Slide23.png'
import slide24 from '../../Media/Slide24.png'

import slide31 from '../../Media/Slide31.png'
import slide32 from '../../Media/Slide32.png'
import slide33 from '../../Media/Slide33.png'
import slide34 from '../../Media/Slide34.png'

const HowItWorks = ({isMobile}) => {

  const howItWorksSliderRef = useRef(null)
  const [activeSlide, setActiveSlide] = useState(0)
  const [isDisabled, setIsDisabled] = useState(false)
  const [prevBtnIsBlocked, setPrevBtnIsBlocked] = useState(false)
  const [nextBtnIsBlocked, setNextBtnIsBlocked] = useState(false)


  const nextButtonHandler = () => {
    if (activeSlide < 2) {
      setActiveSlide(activeSlide + 1)
      howItWorksSliderRef.current.slickNext()
      setIsDisabled(true)
      setTimeout(() => setIsDisabled(false), 500)
    }
  }


  const prevButtonHandler = () => {
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1)
      howItWorksSliderRef.current.slickPrev()
      setIsDisabled(true)
      setTimeout(() => setIsDisabled(false), 500)
    }
  }


  const setSlide = (slide_id) => {
    setActiveSlide(slide_id)
    howItWorksSliderRef.current.slickGoTo(slide_id)
    setIsDisabled(true)
    setTimeout(() => setIsDisabled(false), 500)
  }


  useEffect(() => {

    setPrevBtnIsBlocked(activeSlide === 0 ? true : false)
    setNextBtnIsBlocked(activeSlide === 2 ? true : false)

  }, [activeSlide])


  const howItWorksSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    arrows: false,
    touchMove: false
  };


  return (
    <section id='howItWorks' className='howItWorks'>
        <div className='container'>
            <div className='howItWorks__content'>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <h2>Как {isMobile && <br/>}это работает?</h2>
                </div>
                
                <div className='howItWorks__labels-block' style={{justifyContent: `${activeSlide === 0 ? 'flex-start' : (activeSlide === 1 ? 'center': 'flex-end')}`}}>
                  <button
                    className='howItWorks__label'
                    disabled={isDisabled}
                    onClick={() => setSlide(0)}
                    style={{backgroundColor: `${activeSlide === 0 ? '#80CDFF' : ''}`, color: `${activeSlide === 0 ? '#131313' : ''}`}}
                  >
                    Пользователь
                  </button>

                  <button
                    className='howItWorks__label'
                    disabled={isDisabled}
                    onClick={() => setSlide(1)}
                    style={{backgroundColor: `${activeSlide === 1 ? '#FF9369' : ''}`, color: `${activeSlide === 1 ? '#131313' : ''}`}}
                  >
                    Контент–мэйкер
                  </button>

                  <button
                    className='howItWorks__label'
                    disabled={isDisabled}
                    onClick={() => setSlide(2)}
                    style={{backgroundColor: `${activeSlide === 2 ? '#9DE2C9' : ''}`, color: `${activeSlide === 2 ? '#131313' : ''}`}}
                  >
                    Рекламодатель
                  </button>
                  
                </div>
                <div className='howItWorks__slider'>
                  <Slider ref={howItWorksSliderRef} {...howItWorksSliderSettings}>
                    <HowItWorksSlide
                      key={0}
                      isMobile={isMobile}
                      bgColor={'#80CDFF'}
                      mainTitle={<>Для<br/>пользователя</>}
                      mainTitleMobile={<>Для<br/>пользователя</>}
                      img11={slide11}
                      img12={slide12}
                      img13={slide13}
                      img14={slide14}
                      title1={<>Скачай<br/>приложение</>}
                      title2={<>Выбери один <br/>из арт-объектов<br/>на карте</>}
                      title3={<>Наведи камеру<br/>на фасад<br/>здания</>}
                      title4={<>Делись<br/>впечатлениями<br/>с друзьями</>}
                    />
                    <HowItWorksSlide
                      key={1}
                      isMobile={isMobile}
                      bgColor={'#FF9369'}
                      mainTitle={<>Для<br/>контент–мэйкера</>}
                      mainTitleMobile={<>контент–<br/>мэйкера</>}
                      img11={slide21}
                      img12={slide22}
                      img13={slide23}
                      img14={slide24}
                      title1={<>Выбери одно<br/>из доступных зданий<br/>для своей идеи</>}
                      title2={<>Создай<br/>арт</>}
                      title3={<>Загрузи арт<br/>в платформу через<br/>личный кабинет</>}
                      title4={<>получай доход<br/>за популярность<br/>контента</>}
                    />
                    <HowItWorksSlide
                      key={2}
                      isMobile={isMobile}
                      bgColor={'#9DE2C9'}
                      mainTitle={<>Для<br/>рекламодателя</>}
                      mainTitleMobile={<>Для<br/>рекламодателя</>}
                      img11={slide31}
                      img12={slide32}
                      img13={slide33}
                      img14={slide34}
                      title1={<>Зайдите<br/>в рекламный<br/>кабинет</>}
                      title2={<>Выберите интересующее<br/>здание и формат<br/>интеграции</>}
                      title3={<>Загрузите рекламный<br/>креатив или закажите<br/>спецпроект</>}
                      title4={<>Получай доход<br/>подробные отчеты<br/>через рекламный<br/>кабинет</>}
                    />
                  </Slider>
                  <div className='howItWorks__slider-arrows'>

                    <SwitchButton
                      type='prev'
                      isDisabled={isDisabled}
                      isBlocked={prevBtnIsBlocked}
                      onClickAction={() => prevButtonHandler()}
                    />
                    <SwitchButton
                      type='next'
                      isDisabled={isDisabled}
                      isBlocked={nextBtnIsBlocked}
                      onClickAction={() => nextButtonHandler()}
                    />
                  </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default HowItWorks