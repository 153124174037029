import React, {useEffect, useState} from 'react'
import './Header.css'
import logo from '../../Media/ArtWalkLogo.svg'
import {Link} from 'react-scroll'

const Header = ({isMobile}) => {
  
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false)

  useEffect(() => {
    document.body.style.overflow = `${isMobileMenuActive ? 'hidden' : ''}`
  }, [isMobileMenuActive])

  return (
    <>
        <header className='header'>
            <div className='container'>
                <nav className='header__navbar'>
                    <img src={`${logo}`} alt='logo' className='header__logo'/>
                    <div className={`header__menuIcon ${isMobileMenuActive ? '_active' : ''}`} onClick={()=>setIsMobileMenuActive(prevState => !prevState)}><span></span></div>
                    <div className='header__menu'>
                        <ul className='header__menu-list'>
                            <li>
                                <Link
                                    to="whatIsArtWalk"
                                    spy={true}
                                    smooth={true}
                                    offset={-170}
                                    duration={500}
                                >
                                    <div className='header__menu-dot'></div>
                                    Что такое?
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="mission"
                                    spy={true}
                                    smooth={true}
                                    offset={-110}
                                    duration={500}
                                >
                                    <div className='header__menu-dot'></div>
                                    Миссия
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="howItWorks"
                                    spy={true}
                                    smooth={true}
                                    offset={-110}
                                    duration={500}
                                >
                                    <div className='header__menu-dot'></div>
                                    Как это работает?
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="contacts"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                >
                                    <div className='header__menu-dot'></div>
                                    Контакты
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
        {isMobile &&
            <div className={`mobile__navbar ${isMobileMenuActive ? 'mobile__navbar_active' : ''}`}>
                <nav className='mobile__menu'>
                    <ul className='mobile__menu-list'>
                        <li>
                            <Link
                                to="whatIsArtWalk"
                                spy={true}
                                smooth={true}
                                offset={-90}
                                duration={500}
                                onClick={()=>setIsMobileMenuActive(false)}
                            >
                                <div className='header__menu-dot'></div>
                                Что такое?
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="mission"
                                spy={true}
                                smooth={true}
                                offset={-90}
                                duration={500}
                                onClick={()=>setIsMobileMenuActive(false)}
                            >
                                <div className='header__menu-dot'></div>
                                Миссия
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="howItWorks"
                                spy={true}
                                smooth={true}
                                offset={-90}
                                duration={500}
                                onClick={()=>setIsMobileMenuActive(false)}
                            >
                                <div className='header__menu-dot'></div>
                                Как это работает?
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="contacts"
                                spy={true}
                                smooth={true}
                                offset={-90}
                                duration={500}
                                onClick={()=>setIsMobileMenuActive(false)}
                            >
                                <div className='header__menu-dot'></div>
                                Контакты
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        }
    </>
  )
}

export default Header