import { useState, useEffect, useCallback } from 'react';
import Contacts from '../Contacts/Contacts';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import HowItWorks from '../HowItWorks/HowItWorks';
import MainBanner from '../MainBanner/MainBanner';
import Mission from '../Mission/Mission';
import WhatIsArtwork from '../WhatIsArtwork/WhatIsArtwork';
import { debounce } from 'lodash';
import './App.css';

function App() {

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (windowSize < 1024) {
      setIsMobile(true)
    } else if (windowSize > 1023) {
      setIsMobile(false)
    }
  }, [windowSize])

  const onChange = useCallback(
    debounce(() => {
      setWindowSize(window.innerWidth);
    }, 500),
    [windowSize, setWindowSize]
  );

  useEffect(() => {
    window.addEventListener('resize', onChange);
  }, [onChange]);


  return (
    <div className="App">
      <Header isMobile={isMobile} />
      <MainBanner />
      <WhatIsArtwork />
      <Mission />
      <HowItWorks isMobile={isMobile} />
      <Contacts />
      <Footer isMobile={isMobile} />
    </div>
  );
}

export default App;
