import React from 'react'
import './SwitchButton.css'

const SwitchButton = ({type, onClickAction, isDisabled, isBlocked}) => {
  return (
    <button className={`switchButton ${isBlocked ? 'btnBlocked' : ''}`} onClick={() => onClickAction()} disabled={isDisabled}>
        <div className={`switchButton__arrow ${type === 'next' ? 'arrow-right' : 'arrow-left'}`}></div>
    </button>
  )
}

export default SwitchButton