import React from 'react'
import './ContactCard.css'

const ContactCard = ({name, avatar, jobTitle, bgColor}) => {
  return (
    <div className='contactCard'>
        <div className='contactCard__avatar-block' style={{backgroundColor: bgColor}}>
            {avatar ? <img src={avatar} alt='1' className='contactCard__avatar'/> : <div className='no-avatar'></div>}
        </div>
        <div className='contactCard__inf'>
            <h4>{jobTitle}</h4>
            <h3>{name}</h3>
        </div>
    </div>
  )
}

export default ContactCard