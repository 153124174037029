import React, { useRef } from 'react'
import './Like.css'
import LikeImg from '../../Media/Like.svg'


const Like = ({type}) => {

  const likeCont = useRef(null)
  const flyingHeartsCount = 7

  const launchFlyingHearts = () => {

    for (let i = 0; i < flyingHeartsCount; i++){
        let like = document.createElement('div')
        like.classList.add(`like`)
        like.classList.add(`like${i+1}`)
        like.classList.add(`animation-like${i+1}`)
        likeCont.current.append(like)
        setTimeout(() => like.remove(), 2000)
    }
    
  }

  return (
    <div className='like__container' ref={likeCont}>
        <div className={`like-btn ${type === 'mission' ? 'like-btn-mission' : ''}`} onClick={launchFlyingHearts}>
            <img src={LikeImg} alt='like'/>
        </div>
    </div>
  )
}

export default Like