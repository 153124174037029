import React from 'react'
import './Contacts.css'
import ContactCard from './ContactCard/ContactCard'
import Avatar1 from '../../Media/S_Naumov.png'
import Avatar2 from '../../Media/D_Latifullina.png'
import Avatar3 from '../../Media/I_Filonenko.png'

const Contacts = () => {
  return (
    <section id='contacts' className='contacts'>
        <div className='container'>
            <div className='contacts__content'>
                <h2>Контакты</h2>
                <div className='contacts__list'>
                    <ContactCard
                        name={<>Иван<br/>Филоненко</>}
                        avatar={Avatar3}
                        jobTitle={'СЕО'}
                        // link={'stan@patterndigital.ru'}
                        // phone={'+7 909 949 88 53'}
                        bgColor={'#80CDFF'}
                    />
                    <ContactCard
                        name={<>Станислав<br/>Наумов</>}
                        avatar={Avatar1}
                        jobTitle={'CVO'}
                        // link={'stan@patterndigital.ru'}
                        // phone={'+7 909 949 88 53'}
                        bgColor={'#FF9369'}
                    />
                    <ContactCard
                        name={<>Динара<br/>Латифуллина</>}
                        avatar={Avatar2}
                        jobTitle={'Операционный директор'}
                        // link={'stan@patterndigital.ru'}
                        // phone={'+7 909 949 88 53'}
                        bgColor={'#9DE2C9'}
                    />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Contacts