import React, {useEffect, useRef, useState} from 'react'
import SwitchButton from '../SwitchButton/SwitchButton'
import './Mission.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import slide1 from "../../Media/MissionSlide1.png"
import slide2 from "../../Media/MissionSlide2.png"
import slide3 from "../../Media/MissionSlide3.png"
import Like from '../Like/Like';

const Mission = () => {

  const missionSliderRef = useRef(null)
  const [activeSlide, setActiveSlide] = useState(0)
  const [isDisabled, setIsDisabled] = useState(false)
  const [btnPrevIsBlocked, setBtnPrevIsBlocked] = useState(false)
  const [btnNextIsBlocked, setBtnNextIsBlocked] = useState(false)


  const nextButtonHandler = () => {
    if (activeSlide < 2) {
      setActiveSlide(activeSlide + 1)
      missionSliderRef.current.slickNext()
      setIsDisabled(true)
      setTimeout(() => setIsDisabled(false), 500)
    }
  }


  const prevButtonHandler = () => {
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1)
      missionSliderRef.current.slickPrev()
      setIsDisabled(true)
      setTimeout(() => setIsDisabled(false), 500)
    }
  }


  useEffect(() => {

    setBtnPrevIsBlocked(activeSlide === 0 ? true : false)
    setBtnNextIsBlocked(activeSlide === 2 ? true : false)

  }, [activeSlide])


  const missionSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    arrows: false,
    touchMove: false
  };

  return (
    <section id='mission' className='mission'>
        <div className='container'>
            <div className='mission__content'>
                <h2>Миссия</h2>
                <div className='mission__slider'>

                  <SwitchButton
                    type='prev'
                    onClickAction={() => prevButtonHandler()}
                    isDisabled={isDisabled}
                    isBlocked={btnPrevIsBlocked}
                  />

                  <div className='mission__iphoneMockup'>
                    <div className='mission__slider-block'>
                    <Slider ref={missionSliderRef} {...missionSliderSettings}>
                      <div className='mission__slide' key={1}>
                        <img src={slide1} alt='slide1' />
                      </div>
                      <div className='mission__slide' key={2}>
                        <img src={slide2} alt='slide2' />
                      </div>
                      <div className='mission__slide' key={3}>
                        <img src={slide3} alt='slide3' />
                      </div>
                    </Slider>
                    </div>
                    <div className='mission__like-btn'>
                      <Like type='mission'/>
                    </div>
                  </div>

                  <SwitchButton
                    type='next'
                    onClickAction={() => nextButtonHandler()}
                    isDisabled={isDisabled}
                    isBlocked={btnNextIsBlocked}
                  />
                  
                </div>
                <p>
                    Открыть новые горизонты для творчества современных
                    художников и предоставить платформу, где они смогут
                    эффективно монетизировать свой талант.
                </p>
            </div>
        </div>
    </section>
  )
}

export default Mission