import React from 'react'
import './Footer.css'
import UwalkLogo from '../../Media/UwalkLogo.png'
import VkLogo from '../../Media/VkLogo.svg'
import InstLogo from '../../Media/InstagramLogo.svg'
import FbLogo from '../../Media/FacebookLogo.svg'

const Footer = ({isMobile}) => {
  return (
    <footer className='footer'>
        <div className='container'>
            <div className='footer__content'>
                <p className='footer__rights'>©Artwalk.{isMobile && <br/>} All rights reserved</p>
                <div className='footer__links'>
                    <a href='https://vk.ru/public216556965' target="_blank" rel="noreferrer">
                        <div className='footer__link'>
                            <img src={`${VkLogo}`} alt='Vk'/>
                        </div>
                    </a>
                    <a href='https://instagram.com/artwalk.app?igshid=YmMyMTA2M2Y=' target="_blank" rel="noreferrer">
                        <div className='footer__link'>
                            <img src={`${InstLogo}`} alt='Instagram'/>
                        </div>
                    </a>
                    <a href='https://www.facebook.com/groups/3276917182556142/?ref=share_group_link' target="_blank" rel="noreferrer">
                        <div className='footer__link'>
                            <img src={`${FbLogo}`} alt='Facebook'/>
                        </div>
                    </a>
                </div>
                <div className='footer__powered'>
                    <p>Powered by UWalk</p>
                    <img src={`${UwalkLogo}`} alt='Uwalk-logo' className='footer__uwalk-logo'/>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer