import React from 'react'
import Like from '../Like/Like'
import './MainBanner.css'

const MainBanner = () => {
  return (
    <section className='mainBanner'>
        <div className='container'>
          <div className='mainBanner__content'>
            <div className='mainBanner__iphoneMockup'>
              <h1 className='mainBanner__title'>Меняем рекламный ландшафт большого города через</h1>
              <div className='mainBanner__hashtags-block'>
                <div className='mainBanner__hashtag'>#современное_искусство</div>
                <div className='mainBanner__hashtag ar-hashtag'>#AR-технологии</div>
              </div>
              <div className='mainBanner__like-btn'>
                <Like />
              </div>
            </div>
              <div className='mainBanner__phone-shadow'></div>
          </div>
        </div>
    </section>
  )
}

export default MainBanner