import React from 'react'
import './WhatIsArtwork.css'

const WhatIsArtwork = () => {
  return (
    <section id='whatIsArtWalk' className='whatIsArtwork'>
        <div className='container'>
            <div className='whatIsArtwork__content'>
                <h2>Что такое<br/>Artwalk?</h2>
                <div className='whatIsArtwork__text'>
                  <p>
                    ARtWalk — это платформа, которая при помощи технологий дополненной
                    реальности соединяет контент-мейкеров и рекламодателей с их аудиторией
                    по всему миру.
                  </p>
                  <br/>
                  <br/>
                  <br/>
                  <p>
                    ARtWalk позволяет проецировать виртуальный контент, создаваемый художниками
                    и брендами, на фасады реальных зданий в городах по всему миру.
                  </p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default WhatIsArtwork