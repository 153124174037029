import React from 'react'
import './HowItWorksSlide.css'

const HowItWorksSlide = ({isMobile, bgColor, mainTitle, mainTitleMobile, img11, img12, img13, img14, title1, title2, title3, title4}) => {
  return (
    <div className='howItWorksSlide' style={{backgroundColor: bgColor}}>
        <h3 className='howItWorksSlide__title'>{isMobile ? mainTitleMobile : mainTitle}</h3>
        {isMobile
        
        ?
            <div className='howItWorksSlide__instruction_m'>
                <div className='howItWorksSlide__instruction_m-row'>
                    <div className='howItWorksSlide__instruction-item'>
                        <img src={img11} alt=''/>
                        <h4>{title1}</h4>
                    </div>
                    <div className='howItWorksSlide__instruction-item'>
                        <img src={img14} alt=''/>
                        <h4>{title4}</h4>
                    </div>
                </div>
                <div className='howItWorksSlide__instruction_m-row'>
                    <div className='howItWorksSlide__instruction-item'>
                        <img src={img12} alt=''/>
                        <h4>{title2}</h4>
                    </div>
                    <div className='howItWorksSlide__instruction-item'>
                        <img src={img13} alt=''/>
                        <h4>{title3}</h4>
                    </div>
                </div>
                <div className='dottedArrowRight first-arr'></div>
                <div className='dottedArrowRight second-arr'></div>
                <div className='dottedArrowRight third-arr'></div>
            </div>
        :
        <div className='howItWorksSlide__instruction'>
            <div className='howItWorksSlide__instruction-item'>
                <img src={img11} alt=''/>
                <h4>{title1}</h4>
            </div>
            <div className='dottedArrowRight first-arr'></div>
            <div className='howItWorksSlide__instruction-item'>
                <img src={img12} alt=''/>
                <h4>{title2}</h4>
            </div>
            <div className='dottedArrowRight second-arr'></div>
            <div className='howItWorksSlide__instruction-item hiws-active'>
                <img src={img13} alt=''/>
                <h4>{title3}</h4>
            </div>
            <div className='dottedArrowRight '></div>
            <div className='howItWorksSlide__instruction-item'>
                <img src={img14} alt=''/>
                <h4>{title4}</h4>
            </div>
        </div>
        }
    </div>
  )
}

export default HowItWorksSlide